// 共通（mixinとvariable）
@import "../../asset/common";

// Settingコンポーネントのみに適用されるスタイル

#setting{
  dl{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    @include mqSp(){
      flex-direction: column;
    }
    dd{
      margin-left: 10px;
      @include mqSp(){
        margin-left: 0;
        margin-top: 10px;
      }
      input{
        width: 4.2em;
        margin-right: 5px;
      }
    }
  }
}
