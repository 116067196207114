// 共通（mixinとvariable）
@import "../../asset/common";

// Appコンポーネントのみに適用されるスタイル

#statistics{
  .totalAmount{
    margin-top: 15px;
    text-align: center;
  }
  .tableHeader{
    margin-top: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  .list{
    overflow-y: scroll;
    max-height: 60vh;
    margin-top: 15px;
    > ul{
      > li{
        text-align: center;
        font-size: 14px;
        & + li{
          margin-top: 15px;
        }
        > span{
          display: inline-block;
          min-height: 40px;
          padding-top: 7px;
          padding-left: 45px;
          background-position: left center;
          background-repeat: no-repeat;
          background-size: 40px 40px;
          @include weaponBg;
        }
      }
    }
  }
}
