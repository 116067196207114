// 共通（mixinとvariable）
@import "../../asset/common";

// Appコンポーネントのみに適用されるスタイル

#gachaResult{
  .photo{
    display: block;
    width: 160px;
    height: 160px;
    margin: 0 auto;
    > span{
      display: block;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      @include weaponBg;
    }
  }
  .new{
    margin-bottom: 5px;
    text-align: center;
    > span{
      display: inline-block;
      padding: 3px 5px;
      background-color: $gachaResult-new-bgColor;
      color: $gachaResult-new-color;
      font-size: 12px;
    }
  }
  .name{
    text-align: center;
    font-weight: bold;
  }
}
