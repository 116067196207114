// 共通（mixinとvariable）
@import "../../asset/common";

// Conpaneコンポーネントのみに適用されるスタイル
#conpane{
  display: flex;
  justify-content: center;
  @include mqSp(){
    flex-direction: column;
  }
  div{
    margin: 0 10px;
    & + div{
      @include mqSp(){
        margin-top: 15px;
      }
    }
    button{
      @include btnSpecial;
      @include mqSp(){
        width: 100%;
      }
    }
  }
}
