// 共通（mixinとvariable）
@import "../../asset/common";

// weaponListコンポーネントのみに適用されるスタイル
#weaponList{
  .filter{
    > dl{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      @include mqSp(){
        flex-direction: column;
      }
      dd{
        margin-left: 10px;
        @include mqSp(){
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }
  }
  .myWeapon{
    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;
      li{
        position: relative;
        width: 200px;
        height: 200px;
        margin-top: 20px;
        background-position: center center;
        background-repeat: no-repeat;
        @include mqSp(){
          width: calc( 50% - 10px );
        }
        @include weaponBg;
        &.notOwn::before{
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-color: $myWeapon-notOwn-bgColor;
        }
        &.blank{
          height: 0;
          margin-top: 0;
        }
        > .name{
          position: absolute;
          top: 5px;
          width: 100%;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
        }
        > .button{
          position: absolute;
          bottom: 5px;
          width: 100%;
          text-align: center;
          > button{
            display: inline-block;
            width: 32px;
            height: 32px;
            position: relative;
            top: -2px;
            left: -2px;
            padding: 0;
            margin: 0 5px;
            background-color: #fff;
            border: 2px solid $myWeapon-btn-borderColor;
            border-radius: 16px;
            text-align: center;
            color: $myWeapon-btn-color;
            font-size: 24px;
            line-height: 1.2em;
            cursor: pointer;
            &:focus{
              outline:0;
            }
            &:hover{
              @include mqPc(){
                background-color: $myWeapon-btn-hover-bgColor;
                color: $myWeapon-btn-hover-color;
              }
            }
          }
        }
      }
    }
  }
}
